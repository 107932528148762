import { createSlice } from "@reduxjs/toolkit"

const statisticSlice = createSlice({
  name: 'statistic',
  initialState: {
    planInfo: {},
    statByMonth: {},
    statByDay: []
  },
  reducers: {
    setPlanInfo: (state, { payload }) => {
      state.planInfo = payload
    },
    setDayStat: (state, { payload }) => {
      state.statByDay = payload
    },
    setMonthStat: (state, { payload }) => {
      state.statByMonth = payload
    },
  },
})

export const { setPlanInfo, setDayStat, setMonthStat } = statisticSlice.actions


export const selectPlan = (state: any) => state.statistic.planInfo;
export const selectDayStat = (state: any) => state.statistic.statByDay;
export const selectMonthStat = (state: any) => state.statistic.statByMonth;


export default statisticSlice.reducer

