import { createSlice } from '@reduxjs/toolkit'
import { AuthorizationStatus } from 'shared/libs/const'


const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLogged:
      (typeof window !== 'undefined' && localStorage.getItem('isAuth')) ||
      AuthorizationStatus.NO_AUTH,
  },
  reducers: {
    setIsLogged: (state, { payload }) => {
      state.isLogged = payload
      if (typeof window !== 'undefined') localStorage.setItem('isAuth', payload)
    },
  },
})

export const { setIsLogged } = authSlice.actions

export const selectIsLogged = state => state.auth.isLogged

export default authSlice.reducer
