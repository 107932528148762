import { Card, Statistic, Table } from "antd";
import MarginPerDay from "features/statistic/margin-per-day";
import MarginPerMonth from "features/statistic/margin-per-month";
import StatisticPerDay from "features/statistic/statistic-per-day";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { TableHeader } from "./mockData";
import { selectPlan } from "./model/reducer";

const StatisticCard = () => {
  const [columns, setColumns] = useState([]);
  const planData = useSelector(selectPlan);
  const [activeTab, isActiveTab] = useState('days')

  const getInfo = async () => {
    if (planData?.plan_info) getColumns(planData?.plan_info[0]);
  };

  useEffect(() => {
    getInfo();
  }, [planData]);

  const getColumns = (arr: any) => {
    const columnsArr: any = Object.keys(arr).map((el: any) => {
      const b = {
        title: TableHeader[el],
        dataIndex: el,
        key: el,
        render: (el: any) => {
          const val = Number(el);
          return <span>{isNaN(val) ? el : val.toFixed(4)}</span>;
        },
      };
      return b;
    });
    setColumns(columnsArr);
  };

  return (
    <section className="mt-4 px-8">
      <h2 className="text-center mb-2 text-bold text-lg">Статистика</h2>
      <div className="flex flex-col md:flex-row gap-8 items-start">
        <Card bordered={false}>
          <Statistic
            title="Кол-во свап за месяц"
            value={planData?.swap_transaction_per_month}
          />
        </Card>
        <Card bordered={false}>
          <Statistic
            title="Кол-во свап за все время"
            value={planData?.swap_total_transaction}
          />
        </Card>
        <StatisticPerDay />
      </div>
      <div className="mt-4 mb-4">
        <Table
          columns={columns}
          dataSource={planData?.plan_info}
          className="overflow-scroll max-w-full  bg-white"
        />
      </div>
      <div className="flex items-center justify-center mt-2">
        <button type="button" onClick={()=>isActiveTab('month')} className={`border border-gray p-2 rounded-md ${activeTab === 'month' && 'bg-green text-white font-bold'}`} >Стастистика по месяцам</button>
        <button type="button" onClick={()=>isActiveTab('days')} className={`border border-gray p-2 rounded-md ${activeTab === 'days' && 'bg-green text-white font-bold'}`} >Стастистика по дням</button>
      </div>
      <div className="flex flex-col  gap-4 mb-4">
       {activeTab === 'month' &&  <MarginPerMonth />}

       {activeTab === 'days' &&   <MarginPerDay />}
      </div>
    </section>
  );
};
export default StatisticCard;
