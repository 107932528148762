import { Button, Modal, Table } from "antd"
import { useEffect, useState } from "react"

import { StatisticsApi } from "shared/libs/api"
import { API } from "shared/libs/conf-axios"
import { getDateTime, isSuccessStatusCode } from "shared/libs/utils"

import {  TableHeader } from "./mockData"

const StatisticPerDay = () => {
    const [isModal, setIsModal] = useState(false)

    const [data, setData] = useState([]);
    const [columns,setColumns] = useState<any>([])
  
    const getData = async () => {
      const data = await API.get(StatisticsApi.GET_TRANSACTIONS_BY_DAY);
      if (data && isSuccessStatusCode(data.status)) {
        setData(data.data);
        getColumns(data.data[0])
      }
    };
  
    useEffect(() => {
      if (isModal) {
          getData()
         
      }
    }, [isModal]);

    const getColumns = (arr:any) => {
      const columns = Object.keys(arr).map((el: any) => {
        const b = {
          title: TableHeader[el],
          dataIndex: el,
          key: el,
          render: (el:any) => {
            return <span>{b.key === 'transaction_date' ? getDateTime(el) : el}</span>
            
          }
        };
        return b;
      });
      setColumns(columns)

    }

    

   
    return (
        <>
        <Button type="dashed" onClick={()=>setIsModal(true)}>Показать историю по дням</Button>
        <Modal open={isModal} onOk={()=>setIsModal(false)} onCancel={()=>setIsModal(false)} >
           <Table className="max-w-full w-full overflow-x-scroll" columns={columns} dataSource={data} scroll={{ x: 'max-content' }} />

        </Modal>
        </>

    )
}
export default StatisticPerDay