import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";

import { DatePickerType } from "antd/es/date-picker";

import {
  setFilter,
  setTransactions,
} from "entities/dashboard/ui/transactions/models/reducer";
import { TransactionsApi } from "shared/libs/api";
import { API } from "shared/libs/conf-axios";
import { isSuccessStatusCode } from "shared/libs/utils";

const Date = () => {
  const dispatch = useDispatch();

  const getData = async (date: DatePickerType | number) => {
    const resp = await API.get(`${TransactionsApi.GET_SWAP_HISTORY_BY_DATE}`, {
      params: { specific_date: date },
    });
    if (resp && isSuccessStatusCode(resp.status)) {
      dispatch(setTransactions(resp.data.transactions));
    } else {
      dispatch(setTransactions([]));
    }
  };
  const now = dayjs();
  const onChange = (dateT: any) => {
    if (dateT) {
      const dataUnix = dayjs(dateT.$d).unix();

      dispatch(
        setFilter({
          filterName: "createdAtFrom",
          value: dayjs(dateT.$h).unix(),
        })
      );
      getData(dataUnix);
    } else {
      dispatch(setFilter({ filterName: "createdAtFrom", value: now }));
    }
  };

  return (
    <DatePicker
      inputReadOnly
      className="min-w-[200px]"
      disabledDate={(currentDay) => currentDay > dayjs().endOf("day")}
      defaultValue={dayjs(now, "DD-MM-YYYY")}
      presets={[
        { label: "Yesterday", value: dayjs().add(-1, "d") },
        { label: "Last Week", value: dayjs().add(-7, "d") },
        { label: "Last Month", value: dayjs().add(-1, "month") },
      ]}
      onChange={onChange}
    />
  );
};

export default Date;
