import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { AuthorizationStatus } from "shared/libs/const";
import { Pages } from "shared/libs/path";
import { selectIsLogged } from "./store/authReducer";

const ProtectedRoute = ({ children }:{children:any}) => {
  const auth = useSelector(selectIsLogged)
  
    if (auth !== AuthorizationStatus.AUTH) {
      return <Navigate to={Pages.LOGIN} replace />;
    }
    return children;
  };

  export default ProtectedRoute