import axios from "axios";
import { SyntheticEvent, useState } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

import { AuthApi } from "shared/libs/api";
import { errorStatusHandler, isSuccessStatusCode } from "shared/libs/utils";
import Loader from "shared/ui/loader";
import {  secureToken } from "shared/libs/conf-axios";
import { useDispatch } from "react-redux";
import { setIsLogged } from "app/providers/store/authReducer";
import { AuthorizationStatus } from "shared/libs/const";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pass, setPass] = useState("");
  const [usrm, setUsrn] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState("");

  const getFingerPrint = async () => {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();
    return result.visitorId;
  };

  const submitHandler = async (e: SyntheticEvent) => {
    e.preventDefault();
    const fingerprint = await getFingerPrint();

    setIsLoading(true);
    const data = await axios.post(
      AuthApi.LOGIN,
      { username: usrm, password: pass, fingerprint },
      {
        headers: {
          "X-Access-Token":secureToken
            
        },
      }
    );
    setIsLoading(false);
    console.log(data);

    if (data && isSuccessStatusCode(data.status)) {
      setIsError("");
      setUsrn("");
      setPass("");
      const authData = JSON.stringify({
        a: data?.data?.access_token,
        r: data?.data?.refresh_token,
      });
      localStorage.setItem("session", authData);
      window.localStorage.setItem("isAuth", AuthorizationStatus.AUTH);
      dispatch(setIsLogged(AuthorizationStatus.AUTH));
      return navigate("/");
    } else {
      setIsLoading(false);
      setIsError(errorStatusHandler(data.status));
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-light">
      {isLoading && <Loader />}
      <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
        <div className="font-medium self-center text-xl sm:text-2xl uppercase text-gray-800">
          Login To Your Account
        </div>

        <div className="mt-10">
          <form onSubmit={submitHandler}>
            <div className="flex flex-col mb-6">
              <label
                htmlFor="username"
                className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
              >
                Username:
              </label>
              <div className="relative">
                <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                  </svg>
                </div>

                <input
                  required
                  value={usrm}
                  onChange={(e) => setUsrn(e.target.value)}
                  id="username"
                  type="username"
                  name="username"
                  className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                  placeholder="Username"
                />
              </div>
            </div>
            <div className="flex flex-col mb-6">
              <label
                htmlFor="password"
                className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
              >
                Password:
              </label>
              <div className="relative">
                <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                  <span>
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                    </svg>
                  </span>
                </div>

                <input
                  required
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                  id="password"
                  type="password"
                  name="password"
                  className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                  placeholder="Password"
                />
              </div>
            </div>

            <div className="flex w-full">
              <button
                type="submit"
                className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-blue hover:bg-blue-700 rounded py-2 w-full transition duration-150 ease-in"
              >
                <span className="mr-2 uppercase">Login</span>
                <span>
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>
              </button>
            </div>
            <p className="mt-2 text-center text-error">{isError}</p>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Login;
