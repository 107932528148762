import { Button } from "antd";
import Layout, { Content, Header } from "antd/es/layout/layout";
import axios from "axios";
import Dashboard from "entities/dashboard";
import { AuthApi } from "shared/libs/api";
import {  secureToken } from "shared/libs/conf-axios";
import { AuthorizationStatus } from "shared/libs/const";
import { isSuccessStatusCode } from "shared/libs/utils";

function DashboardPage() {
  const logout = async () => {
    const rt =
      localStorage?.getItem("session") &&
      //@ts-ignore
      JSON.parse(localStorage?.getItem("session"));
    const resp = await axios.post(AuthApi.LOGOUT, null, {
      headers: {
        Authorization: `Bearer ${rt?.r}`,
        "X-Access-Token": secureToken

      },
    });
    if (resp && isSuccessStatusCode(resp.status)) {
      localStorage.removeItem('session')
      localStorage.setItem('isAuth', AuthorizationStatus.NO_AUTH)
      window.location.reload()
    }
  };
  return (
    <Layout>
      <Header className="bg-gray-light flex justify-end items-center">
        <Button type="primary" className="bg-blue p-2" onClick={() => logout()}>
          Выйти
        </Button>
      </Header>
      <Content className="min-h-screen">
        <Dashboard />
      </Content>
    </Layout>
  );
}

export default DashboardPage;
