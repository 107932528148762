export const perDay = [
  {
    transaction_date: 1692738000,
    transaction_count: 1,
  },
  {
    transaction_date: 1692824400,
    transaction_count: 8,
  },
  {
    transaction_date: 1692910800,
    transaction_count: 9,
  },
];

export const TableHeader:any = {
  transaction_date: "Дата",
  transaction_count: "Количество транзакций",
};
