import { Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { selectDayStat } from "entities/dashboard/ui/statictic/model/reducer";

import { getDateTime } from "shared/libs/utils";

const MarginPerDay = () => {
  // const [isModal, setIsModal] = useState(false);
  const initData = useSelector(selectDayStat);
  const [data, setData] = useState([]);

  const getGroupData = (data: any) => {
    const result: any = [];
    const copy = [...data];
    copy?.forEach((el: any) => {
      if (
        result?.length &&
        result?.find((elem: any) => elem.from_date === el.from_date)
      ) {
        let d = result?.find((elem: any) => elem.from_date === el.from_date);
        if (el.token_name === "USDT_ERC20") {
          d["margin_usdt"] = el.daily_margin;
        } else if (el.token_name === "USDC_ERC20") {
          d["margin_usdc"] = el.daily_margin;
        } else {
          d["margin_eth"] = el.daily_margin;
        }

        return d;
      } else {
        if (el.token_name === "USDT_ERC20") {
          result.push({
            from_date: el.from_date,
            margin_usdt: el.daily_margin,
          });
        } else if (el.token_name === "USDC_ERC20") {
          result.push({
            from_date: el.from_date,
            margin_usdc: el.daily_margin,
          });
        } else {
          result.push({
            from_date: el.from_date,
            margin_eth: el.daily_margin,
          });
        }
      }
    });
    setData(result?.reverse());
  };

  const getData = async () => {
    if (initData) {
      getGroupData(initData);
    }
  };

  useEffect(() => {
    getData();
  }, [initData]);

  const columns = [
    {
      title: "Дата",
      key: "from_date",
      dataIndex: "from_date",
      render: (el: any) => <span>{getDateTime(el)}</span>,
    },
    {
      title: "ETH",
      key: "margin_eth",
      dataIndex: "margin_eth",
      render: (el: any) => <span>{el && Number(el).toFixed(6)}</span>,
    },
    {
      title: "USDT",
      key: "margin_usdt",
      dataIndex: "margin_usdt",
      render: (el: any) => <span>{el && Number(el).toFixed(6)}</span>,
    },
    {
      title: "USDC",
      key: "margin_usdc",
      dataIndex: "margin_usdc",
      render: (el: any) => <span>{el && Number(el).toFixed(6)}</span>,
    },
  ];
  return (
    <div className="flex flex-col gap-2">
      <h3 className="font-bold">Статистика маржи по дням</h3>

      <Table
        columns={columns}
        dataSource={data}
        className="max-w-full overflow-auto bg-white"
      />
      {/* <Button type="default" onClick={() => setIsModal(true)}>
        Показать историю по дням
      </Button>
      <Modal
        open={isModal}
        onOk={() => setIsModal(false)}
        onCancel={() => setIsModal(false)}
      >
        <Table columns={columns} />
      </Modal> */}
    </div>
  );
};

export default MarginPerDay;
