import TransactionCard from "features/transactions/transaction-card";
import Date from "features/transactions/date-picker.tsx";
import SearchTransaction from "features/transactions/search";

import { TransactionItem } from "./models";
import { useSelector } from "react-redux";
import { selectTransactions} from "./models/reducer";

const Transactions = () => {
  const transactions = useSelector(selectTransactions);

  return (
    <section className="mt-4 px-4">
      <div className="flex flex-col gap-4">
        <div className="flex">
          <Date />
          <SearchTransaction />
        </div>
        <div>
          Всего:{" "}
          {transactions && transactions?.length ? transactions?.length : 0}
        </div>
        {transactions?.map((el: TransactionItem) => {
          return <TransactionCard item={el} />;
        })}
      </div>
    </section>
  );
};
export default Transactions;
