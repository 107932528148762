const Loader = () => {
  return (
    <div className="fixed z-10 mx-auto">
      <div
        className="w-20 h-20 rounded-full animate-spin
                    border-x-4 border-solid border-blue border-t-transparent"
      ></div>
    </div>
  );
};
export default Loader;
