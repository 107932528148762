import { Modal, Table } from "antd";
import { selectMonthStat } from "entities/dashboard/ui/statictic/model/reducer";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { monthNames } from "shared/libs/utils";
import { ColumnType } from "shared/types";
import {
  Concrete,
  MarginArr,
  MonthlyMarginArray,
  MonthlyMarginType,
} from "./model";

const MarginPerMonth = () => {
  const data = useSelector(selectMonthStat);
  const [isModal, setIsModal] = useState(false);
  const [groupData, setGroupData] = useState<Concrete<MonthlyMarginType> | any>(
    {}
  );

  const getData = async () => {
    if (data) {
      getGroupData(data);
    }
  };

  const getGroupData = (data: MonthlyMarginArray) => {
    const result = new Map();
   
      data?.forEach((itm: MarginArr) => {
        itm?.forEach((el: MonthlyMarginType) => {
            //@ts-ignore
          if (result[el.from_date]) {
            //@ts-ignore
            result[el.from_date].push(el);
          } else {
            //@ts-ignore
            result[el.from_date] = [el];
          }
        });
       
        setGroupData(result);
      });
    }
  useEffect(() => {
    getData();
  }, [data]);

  const columns = [
    {
      title: "Дата",
      key: "date",
      dataIndex: "date",
    },
    {
      title: "Токен",
      key: "token",
      dataIndex: "token",
    },
    {
      title: "План",
      key: "monthly_plan",
      dataIndex: "monthly_plan",
    },
    {
      title: "Маржа USDT",
      key: "monthly_margin",
      dataIndex: "monthly_margin",
    },
    {
      title: "Выполнено факт",
      key: "fact",
      dataIndex: "fact",
    },

    {
      title: "Выполнено %",
      key: "how_much_is_done",
      dataIndex: "how_much_is_done",
      render: (el:any) => {
        return <span>{parseInt(el)}</span>;
      },
    },

    {
      title: "Комиссия",
      key: "provider_margin",
      dataIndex: "provider_margin",
    },
  ];
  return (
    <div className="flex flex-col gap-2">
      <h3 className="font-bold">Статистика по месяцам</h3>
      <div className="b">
        <div className="flex gap-2 bg-white max-w-full lg:max-w-[900px] overflow-auto p-2 rounded-md mb-2">
          {columns?.map((el: ColumnType) => {
            return (
              <div
                className={`${
                  el.title === "Дата"
                    ? "w-[60px]"
                    : el.title === "План"
                    ? "w-[150px]"
                    : "w-[100px]"
                }`}
                key={el.key}
              >
                {el.title}
              </div>
            );
          })}
        </div>
        {Object.keys(groupData)?.map((el: string | number) => {
          return (
            <div
              key={el}
              className="flex items-center gap-2 mb-4 max-w-full bg-white lg:max-w-[900px] overflow-auto p-2 rounded-md"
            >
              <h2 className="w-[60px]">
                {monthNames[new Date(+el * 1000).getMonth()]}
              </h2>
              <div>
                {groupData[el]?.map((item: MonthlyMarginType) => {
                  return (
                    <div
                      className="flex gap-2"
                      key={`${item.token_name}_${item.monthly_margin}`}
                    >
                      <p className="w-[100px]">{item.token_name}</p>

                      <p className="w-[150px]">
                        {Number(item.monthly_plan).toFixed(4)}
                      </p>

                      <p className="w-[100px]">
                        {Number(item.monthly_margin).toFixed(4)}
                      </p>
                      <p className="w-[100px]">
                        {Number(item.fact).toFixed(4)}
                      </p>
                      <p className="w-[100px]">
                        {item.how_much_is_done &&
                          Number(item.how_much_is_done).toFixed(4)}
                        %
                      </p>
                      <p className="w-[100px]">
                        {Number(item.provider_margin).toFixed(4)}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        {/* <Button type="default" onClick={() => setIsModal(true)}>
          Показать историю по месяцам
        </Button> */}
      </div>
      <Modal
        open={isModal}
        onOk={() => setIsModal(false)}
        onCancel={() => setIsModal(false)}
      >
        <Table columns={columns} dataSource={data} />
      </Modal>
    </div>
  );
};

export default MarginPerMonth;
