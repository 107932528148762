import React from "react";
import ReactDOM from "react-dom/client";
import "app/styles/index.css";
import Router from "app/providers/routes";
import { WithStore } from "app/providers/with-store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <WithStore>
    <Router />
  </WithStore>
);
