import { useDispatch } from "react-redux";

import Search from "antd/es/input/Search";

import { setTransactions } from "entities/dashboard/ui/transactions/models/reducer";

import { TransactionsApi } from "shared/libs/api";
import { API } from "shared/libs/conf-axios";
import { isSuccessStatusCode } from "shared/libs/utils";

const SearchTransaction = () => {
  const dispatch = useDispatch();
  const getData = async (text: string) => {
    if (text) {
      const resp = await API.get(TransactionsApi.GET_TRANSACTION, {
        params: { transaction_identifier: text },
      });
      if (resp && isSuccessStatusCode(resp.status)) {
        dispatch(setTransactions(resp.data.transactions));
      } else {
        dispatch(setTransactions([]));
      }
    }
  };

  return (
    <Search placeholder="Find by address" onSearch={(e: string) => getData(e)} />
  );
};
export default SearchTransaction;
