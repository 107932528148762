import { createSlice } from "@reduxjs/toolkit"

export const initialFilter: any = {
  search: '',
  createdAtFrom: null,
}

 const transactionsSlice = createSlice({
  name: 'transactions',
  initialState: {
    filter: initialFilter,
    transactions: []
  },
  reducers: {
    setFilter: (state, action) => {
      const { filterName, value } = action.payload
      state.filter[filterName] = value
    },
    resetFilter: (state, action) => {
      state.filter = initialFilter
    },
    setTransactions: (state, { payload }) => {
      state.transactions= payload
    }
  },
})

export const { setFilter, setTransactions } = transactionsSlice.actions

export const selectTransactionsFilter = (state: any) => state.transactions.filter;
export const selectTransactions = (state: any) => state.transactions.transactions;

export default transactionsSlice.reducer

