import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { StatisticsApi, TransactionsApi } from "shared/libs/api";
import { API } from "shared/libs/conf-axios";
import { isSuccessStatusCode } from "shared/libs/utils";
import Loader from "shared/ui/loader";
import StatisticCard from "./ui/statictic";
import {
  setDayStat,
  setMonthStat,
  setPlanInfo,
} from "./ui/statictic/model/reducer";
import Transactions from "./ui/transactions";
import { setTransactions } from "./ui/transactions/models/reducer";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [statisticLoading, isStatisticLoading] = useState(false);
  const [transLoading, isTransLoading] = useState(false);
  const getData = async () => {
    isStatisticLoading(true);
    isTransLoading(true);
     await API.get(StatisticsApi.GET_PLAN).then(async (resp) => {
      if (resp && isSuccessStatusCode(resp.status)) {
        dispatch(setPlanInfo(resp.data));
        await API.get(StatisticsApi.GET_MARGIN_MONTH).then(async (response) => {
          if (response && isSuccessStatusCode(response.status)) {
            dispatch(setMonthStat(response.data));
          }

          await API.get(StatisticsApi.GET_MARGIN_DAY).then(async (response) => {
            if (response && isSuccessStatusCode(response.status)) {
              dispatch(setDayStat(response.data));
              isStatisticLoading(false);
            }
            await API.get(TransactionsApi.GET_SWAP_HISTORY_TODAY).then(
              async (data) => {
                if (data && isSuccessStatusCode(data.status)) {
                  dispatch(setTransactions(data.data.transactions));
                  isTransLoading(false);
                } else {
                  isTransLoading(false);
                }
              }
            );
          });
        });
      } else {
        isStatisticLoading(false);
        isTransLoading(false);
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="lg:grid md:grid-cols-[35%_1fr] relative">
 
      {transLoading ? <Loader /> : <Transactions />}
      {statisticLoading ? <Loader /> : <StatisticCard />}
    </div>
  );
};
export default Dashboard;
