export const planData = {
    "plan_info": [
      {
        "token_name": "ETH",
        "monthly_plan": "2134327148719",
        "fact": "23423525.234634576546",
        "should_be_done": "42142356755321",
        "average_margin_per_day": "68849262861.90322580645161290"
      },
      {
        "token_name": "USDT",
        "monthly_plan": "2134327148719",
        "fact": "5325243",
        "should_be_done": "43525",
        "average_margin_per_day": "68849262861.90322580645161290"
      },
      {
        "token_name": "USDC",
        "monthly_plan": "2134327148719",
        "fact": "4214",
        "should_be_done": "54325",
        "average_margin_per_day": "68849262861.90322580645161290"
      }
    ],
    "swap_transaction_per_month": "18",
    "swap_total_transaction": "18"
  }


  export const TableHeader:any = {
    "token_name": "Токен",
        "monthly_plan": "План/мес",
        "fact": "План/факт",
        "should_be_done": "Должно быть",
        "average_margin_per_day": "Ср. маржа для достижения"
  }