import { configureStore } from "@reduxjs/toolkit";
import transactionsReducer from "entities/dashboard/ui/transactions/models/reducer";
import authReducer from "./authReducer";
import statisticReducer from "entities/dashboard/ui/statictic/model/reducer";


export default configureStore({
  reducer: {
    transactions: transactionsReducer,
    auth: authReducer,
    statistic: statisticReducer
  },
});
