import { Card} from "antd";

import Paragraph from "antd/es/typography/Paragraph";
import { LinkOutlined } from "@ant-design/icons";

import { TransactionItem } from "entities/dashboard/ui/transactions/models";

import { getDateTime, getNetworkLink } from "shared/libs/utils";

const TransactionCard = ({ item }: { item: TransactionItem }) => {
  const {
    transaction_hash,
    from_token,
    from_token_amount,
    to_token,
    to_token_amount,
    transaction_margin,
    owner_address,
    transaction_datetime,
  } = item;

  return (
    <Card className="max-w-full">
      <div className="flex flex-col justify-between items-start">
        <div className="flex flex-col gap-2">
          <div className="flex flex-col items-base gap-2">
            <a
              rel="noreferrer"
              href={getNetworkLink(from_token, transaction_hash)}
              target={"_blank"}
            >
              {" "}
              <LinkOutlined />
            </a>
            <strong>Hash:</strong>
            <Paragraph copyable={{ text: transaction_hash }}>
              {transaction_hash}
            </Paragraph>
          </div>
          <div className="flex flex-col items-base gap-2">
            <strong>Address:</strong>
            <p className="text-xs md:text-sm">{owner_address}</p>
          </div>
          <div className="flex flex-col  items-start gap-2">
            <strong>
              {from_token} {"->"} {to_token}:
            </strong>
            <span>
              {from_token_amount} {from_token} {"->"} {to_token_amount}{" "}
              {to_token}
            </span>
          </div>
          <div className="flex flex-col items-start gap-2">
            <strong>Дата :</strong>
            <span>{getDateTime(transaction_datetime)}</span>
          </div>
        </div>
        <div className="min-w-[250px] border border-gray p-2 rounded-md flex  items-center gap-2 mt-2">
          <span className="text-gray font-bold">Маржа</span>
          <span className="text-[12px]">
            {Number(transaction_margin).toFixed(6)}{" "}
          </span>
        </div>
      </div>
    </Card>
  );
};

export default TransactionCard;
